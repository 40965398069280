.textContainer {
    position: relative;
    isolation: isolate;
    margin-top: 4px;
}

.textInput {
    background-color: #FFFFFF;
    border-color: #FFC200;
    outline: #FFC200;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    line-height: 20px;
    width: -webkit-fill-available;
    font-family: "OpenSans";

    &.multiline {
        resize: vertical;
    }
}

.eye {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}