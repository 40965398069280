.groupPageTitle {
    color: #FFC200;
    font-family: "Pacifico";
    font-size: 28px;
    line-height: 38px;

    > p {
        margin: 8px 0;
    }
}

.groupInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.submissionButtons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 16px;
}