.aboutUsContainer {
    padding: 16px 0;
    gap: 38px;
    display: flex;
    flex-direction: column;

    .aboutUsTitle {
        text-align: center;
        color: #FFC200;
        font-family: "Pacifico";
        font-weight: 500;
        margin-bottom: 0;
        line-height: 48px;
    }

    .aboutUsHeader {
        color: #FFC200;
        font-family: "Pacifico";
        font-size: 28px;
        line-height: 30px;
        margin: 0 0 16px;
        padding: 0 16px;
    }

    .ourMissionContainer {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0 16px;
        
        @media only screen and (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
            gap: 48px;
        }

        .ourMissionInfo {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .aboutUsHeader {
                padding: 0;
                margin: 0;
            }
        }

        .imageContainer {
            height: 100%;
            width: 100%;
            border-radius: 4px;
        }
    }

    .whatWeDoContainer {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0 16px;
        
        @media only screen and (min-width: 768px) {
            grid-auto-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-columns: 1fr 10fr 1fr 1fr 10fr 1fr;
        }

        .whatWeDoInfo {
            border: 4px solid #556b2f80;
            border-radius: 4px;
            margin: 8px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .titleContainer {
                display: flex;
                justify-content: center;

                h2 {
                    margin: 0;
                    font-family: "Quicksand";
                }
            }
            
            p {
                margin-bottom: 0;
            }
        }
    }

    .ourValuesContainer {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        padding: 0 16px;
        
        @media only screen and (min-width: 768px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        > div {
            border: 4px solid #556b2f80;
            border-radius: 4px;
            padding: 16px;

            .titleContainer {
                display: flex;
                justify-content: center;
                text-align: center;

                h3 {
                    margin: 0;
                    font-family: "Quicksand";
                }
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    .joinUsContainer {
        display: grid;
        grid-template-columns: 0 2fr 0;

        @media only screen and (min-width: 768px) {
            grid-template-columns: 1fr 2fr 1fr;
        }

        .sidebar {
            background-color: #ffda66;
            margin: 16px 0;
        }

        .info {
            border: 8px solid #ffda66;
            border-radius: 4px;
            padding: 16px;

            .aboutUsHeader {
                text-align: center;
            }
        }
    }

    .getInTouchContainer {
        padding: 0 16px;
    }
}