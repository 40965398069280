.dashboardContainer {
    display: flex;
    align-items: center;
    height: 100%;
    width: -webkit-fill-available;

    .cardContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        width: 100%;
    }
}