.faqContainer {
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    max-height: calc(100dvh - 134px); // subtract header, footer, and filter bar

    .faqHeader {
        color: #FFC200;
        font-family: "Pacifico";
        font-size: 28px;
        line-height: 30px;
        margin: 0 0 16px;
    }

    .faqQAContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .faqQuestion {
            font-weight: 600;
            margin: 0 0 16px;
        }
    }
}