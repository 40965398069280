.mobileContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 8px;

    @media only screen and (min-width: 768px) {
        display: none;
    }
}