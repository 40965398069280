.childContainer {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    background-color: #ffda66;
    border-bottom: 1px solid #FFC200;
    max-height: calc(100dvh - 77px); // subtract header and footer

    @media only screen and (min-width: 768px) {
        max-height: calc(100dvh - 104px); // subtract header and footer
    }
}

.childContent {
    background-color: #FFFFFF;
    width: 100%;
    overflow-x: hidden;

    @media only screen and (min-width: 768px) {
        width: 90%;
    }
}