.groupTableContainer {
    &.modalShown {
        display: none;

        @media only screen and (min-width: 768px) {
            display: block;
        }
    }

    .createDesktop {
        display: none;

        @media only screen and (min-width: 768px) {
            display: block;
        }
    }

    .createMobile {
        display: block;

        > button {
            width: 100%;
        }

        @media only screen and (min-width: 768px) {
            display: none;
        }
    }

    .groupTitleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        color: #FFC200;
        
        .titleContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            
            @media only screen and (min-width: 768px) {
                width: auto;
            }

            .title {
                font-family: "Pacifico";
                font-size: 28px;
                line-height: 38px;
            }

            .chevron {
                display: block;
                
                @media only screen and (min-width: 768px) {
                    display: none;
                }

                &.openedChevron {
                    transform: rotate(180deg);
                }
            }
        }
    }
    
    .groupTableContainer {
        display: block;

        &.mobileClosed {
            display: none;

            @media only screen and (min-width: 768px) {
                display: block;
            }
        }

        .groupTable {
            display: grid;
            grid-template-columns: repeat(12, 1fr);

            &.cellGroup {
                max-height: none;
                overflow-y: visible;

                @media only screen and (min-width: 768px) {
                    max-height: 250px;
                    overflow-y: auto;
                }
            }

            > div {
                display: none;
                align-items: center;
                word-break: break-all;

                @media only screen and (min-width: 768px) {
                    display: flex;
                }
            }

            .groupNameCell {
                display: flex;
                grid-column: 1 / 12;
                align-items: flex-start;

                &.cell {
                    color: #0000EE;

                    @media only screen and (min-width: 768px) {
                        color: #000000;
                    }
                }

                @media only screen and (min-width: 768px) {
                    grid-column: 1 / 5;
                    
                    &.expandedGroupName {
                        grid-column: 1 / 6;
                    }
                }
            }

            .groupLeaderCell {
                grid-column: 6 / 11;
            }

            .groupMemberCell {
                grid-column: 5 / 8;
            }

            .groupInviteCell {
                grid-column: 8 / 11;
            }

            .actionsCell {
                grid-column: 11 / 13;
            }

            .header {
                padding: 8px;
                border: 1px solid black;
                border-right: none;
                justify-content: center;
                display: none;

                @media only screen and (min-width: 768px) {
                    display: flex;
                }

                &:first-of-type {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                &.actions {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-right: 1px solid black;
                }
            }

            .cell {
                padding: 16px;
                border-bottom: 1px solid black;
                flex-direction: column;
                justify-content: flex-start;
                gap: 8px;

                &.actionContainer {
                    flex-direction: column;
                    justify-content: center;
                    gap: 16px;

                    > button {
                        width: 100%;
                    }
                }

                &.lastRow {
                    border-bottom: none;
                }
            }
        }
    }

    .emptyTable {
        padding: 0 8px;
    }
}
