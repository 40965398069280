.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
    .loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #FFC200;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
        caret-color: transparent;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}