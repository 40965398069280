.navList {
    background-color: #FFF;

    .menuItem {
        padding: 8px 24px;
        border-radius: 4px;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
            background-color: #F5F5F5;
        }
    }

    .logoutButtonContainer {
        white-space: nowrap;

        > button {
            width: 100%;
            border-radius: 0;
        }
    }
}