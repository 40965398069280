.datePicker {
    border: 1px solid #FFC200;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    line-height: 20px;
    caret-color: transparent;
    cursor: pointer;
    color: #002147;
    font-family: 'OpenSans';
    width: 100%;
    outline: #FFC200;
    width: -webkit-fill-available;
}