.buttonContainer {
    display: inline-block;
    margin-top: 8px;
    padding-bottom: 4px;
    padding-top: 4px;

    @media only screen and (min-width: 768px) {
        margin-top: 8px;
        padding-bottom: 4px;
        padding-top: 4px;
    }
}