.settingsContainer {
    height: 100%;

    .settingInputsContainer {
        display: flex;
        justify-content: center;
        height: 100%;
        overflow-y: auto;

        .settingInputs {
            width: 80%;
            padding: 16px 0;

            .sectionContainer {
                padding: 16px 0;

                .title {
                    color: #FFC200;
                    font-family: "Pacifico";
                    font-size: 28px;
                    line-height: 38px;
                    margin: 0 0 8px;
                }

                .deleteAccountButton {
                    display: flex;
                    justify-content: flex-end;
                    gap: 28px;
                    display: flex;
                    flex: 1;

                    > * {
                        width: 100%;
                    }
                }
            }

            &.isDeletingUser {
                display: none;

                @media only screen and (min-width: 768px) {
                    display: block;
                }
            }
        }
    }
}