.headerText {
    @media only screen and (min-width: 768px) {
        margin-top: 0;
    }
}

.editContentsContainer {
    gap: 16px;
    display: flex;
    flex-direction: column;

    .advancedOptionsContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.opened {
            border-top: 1px solid #556B2F;
            border-bottom: 1px solid #556B2F;
            padding: 8px 0;
        }

        > h3 {
            margin: 0;
            color: #556B2F;
        }

        .openedChevron {
            transform: rotate(180deg);
        }
    }

    .respondToButtonContainer {
        display: flex;
        flex-direction: row;
        gap: 28px;
        padding-top: 16px;

        .buttonContainer {
            display: flex;
            flex: 1;

            > button {
                width: 100%;
            }
        }
    }
}