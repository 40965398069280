.emptyDisplayContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .emptyDisplay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
        border-radius: 4px;
        padding: 8px;

        .mainText {
            text-align: center;
        }
    }
}