.pageTitle {
    font-family: "Pacifico";
    font-size: 38px;
    margin: 0;
    color: #FFC200;
}

.moderationTable {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &.content {
        max-height: 300px;
        overflow-y: auto;

        > div {
            padding: 8px;
            border-bottom: 1px solid #000;
        }
    }

    .tableHeader {
        font-family: "Pacifico";
        font-size: 24px;
        line-height: 34px;
        padding: 8px 0;
        border-bottom: 1px solid #000;
    }

    .eventPreviewLink {
        cursor: pointer;
        color: #0000EE;
    }
}