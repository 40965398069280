.filtersContainer {
    padding: 0 12px;
    background-color: #002A4D;
    box-shadow: 0px 4px 5px hsla(0, 97%, 14%, 0.219);
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (min-width: 768px) {
        padding: 4px 12px;
    }

    .sideContainer {
        display: flex;
        align-items: center;
        grid-column: 1 / 2;
        grid-row: 1;

        .filterLogo {
            fill: #FFC200;
            cursor: pointer;
            height: 24px;
            width: 24px;
        }
    }

    .titleContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: 2 / 3;
        grid-row: 1;
        color: #FFC200;
        font-family: "Pacifico";
        margin: 8px 0;
        font-size: 24px;
        line-height: 30px;

        @media only screen and (min-width: 768px) {
            font-size: 28px;
            line-height: 38px;
        }
    }

    .filters {
        position: absolute;
        background-color: #FFF;
        padding: 16px;
        border-radius: 4px;
        border: 4px solid #002147;
        width: 100%;
        top: 110%;
        left: 0;
        display: none;
        z-index: 100;

        @media only screen and (min-width: 768px) {
            display: block;
        }

        .respondToButtonContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 16px;
            gap: 28px;

            .buttonContainer {
                display: flex;
                flex: 1;

                > button {
                    width: 100%;
                }
            }
        }
    }
}