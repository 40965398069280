.menuContainer {
    position: relative;

    .menuButton {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 32px;
        width: 32px;
        cursor: pointer;

        > span {
            width: 100%;
            height: 5px;
            background-color: #FFC200;
            border-radius: 4px;;
        }
    }

    .menuList {
        position: absolute;
        right: 0;
        background-color: #FFFFFF;
        border: 1px solid #556B2F;
        border-radius: 4px;
        display: none;
    
        @media only screen and (min-width: 768px) {
            display: block;
        }

        .menuItem {
            padding: 8px 24px;
            border-radius: 4px;
            cursor: pointer;
            white-space: nowrap;

            &:hover {
                background-color: #F5F5F5;
            }
        }

        .logoutButtonContainer {
            white-space: nowrap;

            > button {
                width: 100%;
            }
        }
    }
}