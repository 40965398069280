.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 32px;
    background-color: white;
    position: sticky;
    bottom: 0;
    z-index: 100;
    border-top: #FFC200 1px solid;
    font-size: 12px;
    gap: 8px;

    @media only screen and (min-width: 768px) {
        font-size: 16px;
    }
    
    p {
        margin: 0;

        a {
            text-decoration: none;
        }
    }

    .copyright {
        font-size: 12px;
        display: none;
        
        @media only screen and (min-width: 768px) {
            display: block;
        }
    }
    
    .copyrightMobile {
        font-size: 8px;
        display: block;

        @media only screen and (min-width: 768px) {
            display: none;
        }
    }
}