.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
};

.radioButtonOption {
    display: flex;
    gap: 8px;
    padding: 4px 0;
};

.radioButton {
    border: 1px solid #FFC200;
    border-radius: 25px;
    padding: 0 10px;
    cursor: pointer;
    caret-color: transparent;
};

.radioButtonSelected {
    background-color: #FFC200;
};