.modal {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;

    @media only screen and (min-width: 768px) {
        display: flex;
    }

    .modalBackground {
        position: absolute;
        display: flex;
        background-color: #F5F5F5;
        width: 100%;
        height: 100%;
        opacity: .75;
        z-index: 0;
    }

    .modalContents {
        width: 75%;
        height: fit-content;
        max-height: 75%;
        background-color: #FFFFFF;
        z-index: 1;
        overflow-y: auto;
        padding: 16px;
        border: 1px solid #FFC200;
        border-radius: 4px;

        &.thin {
            width: 25%;
        }
    }
}