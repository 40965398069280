.title {
    color: #FFC200;
    font-family: "Pacifico";
    font-size: 28px;
    line-height: 38px;
    margin: 8px 0;
}

.profileInputs {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .respondToButtonContainer {
        display: flex;
        flex-direction: row;
        gap: 28px;
        padding-top: 12px;
    
        .buttonContainer {
            display: flex;
            flex: 1;
    
            > button {
                width: 100%;
            }
        }
    }
}