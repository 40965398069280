.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-bottom: 4px solid #FFC200;
}

.eventsContainer {
    display: flex;
    height: 100%;

    .eventsList {
        display: block;
        width: 100%;
        min-width: 100%;

        @media only screen and (max-width: 768px) {
            &.selectedEventList {
                display: none;
            }
        }

        @media only screen and (min-width: 768px) {
            width: 33%;
            min-width: 33%;
        }

        border-right: #ffda66 solid 8px;
    }

    .eventsDisplay {
        display: none;
        width: 100%;
        background-color: #ffda66;

        &.selectedEventDisplay {
            display: block;
        }

        @media only screen and (min-width: 768px) {
            display: block;
            padding: 8px 0;
        }
    }
}

.heroContainer {
    display: flex;
    background-image: url('../../assets/images/hero.jpg');
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    caret-color: transparent;
    flex-direction: column;
    color: #FFC200;
    font-family: "Pacifico";

    .heroText {
        font-size: 72px;
        line-height: 100px;
    }

    .heroDescription {
        color: #FFC200;
        font-size: 38px;
        font-family: "Pacifico";
    }

    .downloadDescription {
        font-size: 32px;
        line-height: 44px;
        font-family: "Pacifico";
        margin: 48px 0 24px;
    }

    .qrContainer {
        display: flex;
        justify-content: space-between;
        gap: 64px;
        font-size: 24px;
        line-height: 34px;

        .qrSection {
            display: flex;
            flex-direction: column;
            align-items: center;

            .fauxQR {
                background-color: white;
                height: 180px;
                width: 180px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 16px;

                > div {
                    background-color: black;
                    height: 80%;
                    width: 80%;
                }
            }
        }
    }
}