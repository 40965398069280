.filters {
    .filterInputs {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .respondToButtonContainer {
        display: flex;
        flex-direction: row;
        gap: 28px;
        padding-top: 12px;
        position: sticky;
        bottom: 0;
        background-color: #FFF;
        padding-bottom: 8px;

        .buttonContainer {
            display: flex;
            flex: 1;

            > button {
                width: 100%;
            }
        }
    }
}