.text{
    text-align: center;
}

.deleteGroupInputs {
    display: flex;
    gap: 8px;
    padding-top: 8px;

    > div {
        display: flex;
        width: 100%;

        > button {
            width: 100%;
        }
    }
}