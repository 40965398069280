.autocompleteContainer {
    border: 1px solid #FFC200;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    line-height: 20px;
    color: #002147;
    font-family: 'OpenSans';
    width: 100%;
    outline: #FFC200;
    width: -webkit-fill-available;
}

.autocompleteInput {
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    padding: 2px 0;
    height: 'auto';
}

.autocompleteAddress {
    color: #556B2F;
}