.photoRollContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subImagesContainerMobile {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-around;
    padding: 8px 0;
    overflow: auto;
    width: 100%;
    scrollbar-width: thin;

    @media only screen and (min-width: 768px) {
        display: none;
        background-color: none;
        padding: 16px 0;
    }
}

.selectedImageContainer {
    height: 25vh;
    width: 100%;
    background-size: contain;
    
    .imageBlurContainer {
        display: flex;
        justify-content: center;
        height: 100%;
        backdrop-filter: blur(20px);

        .selectedImage {
            background-color: #FFC200;
            height: inherit;
        }

        .subImagesContainer {
            display: none;
            flex-direction: row;
            justify-content: center;
            align-items: end;
            gap: 8px;
            overflow: auto;
            width: 100%;
            scrollbar-width: thin;
            position: absolute;
            bottom: 0;
            right: 50;
        }

        .circleContainer {
            display: none;
            flex-direction: row;
            justify-content: center;
            position: absolute;
            bottom: 0;
            right: 50;
            gap: 8px;
            padding-bottom: 4px;

            @media only screen and (min-width: 768px) {
                display: flex;
            }

            .circle {
                border-radius: 50%;
                border: 5px solid #002A4D;

                .innerCircle {
                    border-radius: 50%;
                    border: 4px solid #FFC200;
                }
            }
        }

        &:hover {
            @media only screen and (min-width: 768px) {
                .subImagesContainer {
                    display: flex;
                }

                .circleContainer {
                    display: none;
                }
            }
        }
    }
}

.squareContainer {
    border-radius: 4px;
}

.circularContainer {
    border-radius: 280px;
    background-color: #FFC200;
}

.imageContainer {
    height: 80px;
    width: 80px;
    border-radius: 4px;
    cursor: pointer;
    
    @media only screen and (min-width: 768px) {
        height: 30px;
        width: 30px;
        border: 2px solid #002A4D;

        &:hover {
            height: 80px;
            width: 80px;
        }
    }
}