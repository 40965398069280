.photoCellsContainer {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    caret-color: transparent;
}

.photoCell {
    background-color: #FFFFFF;
    border: 1px solid #FFC200;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    aspect-ratio: 1 / 1;
    width: -webkit-fill-available;
    cursor: pointer;
}

.emptyCell {
    background-color: #FFFFFF;
}

.photoCellImage {
    border-radius: 4px;
    height: 100%;
    width: 100%;
}

.deleteIcon {
    background-color: #FF6347;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
}

.optionsContainer {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.options {
    width: 80%;
    padding: 12px;
    gap: 12px;
    background-color: #FFFFFF;
    border-radius: 4px;
    border-width: 1px;
    border-color: #FFC200
}

.modalBlur {
    position: absolute;
    background-color: #F5F5F5;
    opacity: .90;
    height: 100%;
    width: 100%
}

.fileInput {
    display: none;
}