.contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    gap: 28px;

    > div {
        width: 80%;
    }
}