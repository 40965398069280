.button {
    border: 1px solid #FFC200;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    padding: 8px;
    caret-color: transparent;
    cursor: pointer;
    font-family: 'Quicksand';

    &.smButton {
        font-size: 12px;
        line-height: 15px;
    }
    
    &.mdButton {
        font-size: 16px;
        line-height: 20px;
    }

    &.lgButton {
        font-size: 16px;
        line-height: 20px;

        @media only screen and (min-width: 768px) {   
            font-size: 24px;
            line-height: 34px;
        }
    }

    &.primaryButton {
        background-color: #FFC200;
        border-color: #FFC200;
        color: #002147;

        &:focus {
            background-color: #e6ac00;
            border-color: #e6ac00;
            color: #002147;
        }
    }

    &.secondaryButton {
        background-color: #FFFFFF;
        border-color: #FFC200;
        color: #FFC200;

        &:focus {
            background-color: #dddddd;
            border-color: #e6ac00;
            color: #e6ac00;
        }
    }

    &.ghostButton {
        background-color: transparent;
        border-color: transparent;
        color: #556B2F;

        &:focus {
            background-color: #dddddd;
            border-color: #dddddd;
            color: #070707;
        }
    }

    &.destructiveButton {
        background-color: #FF6347;
        border-color: #FF6347;
        color: #FFFFFF;

        &:focus {
            background-color: #E55A3E;
            border-color: #E55A3E;
            color: #dddddd;
        }
    }

    &.ctaButton {
        background-color: #556B2F;
        border-color: #556B2F;
        color: #FFFFFF;

        &:focus {
            background-color: #3b4b21;
            border-color: #3b4b21;
            color: #dddddd;
        }
    }

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}