.dropdownContainer {
    position: relative;
    
    .dropdownSelector {
        background-color: #FFFFFF;
        border: 1px solid #FFC200;
        border-radius: 4px;
        padding: 4px 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        cursor: pointer;
    }

    .openChevronContainer {
        transform: rotateZ(180deg);
    }

    .optionsSelector {
        position: absolute;
        background-color: #FFFFFF;
        border: 1px solid #FFC200;
        border-radius: 4px;
        z-index: 200;
        max-height: 200px;
        overflow-y: scroll;
        cursor: pointer;
    }

    .optionContainer {
        padding: 4px 8px;
    }

    .optionContainer:focus, .optionContainer:hover {
        background-color: #D9D9D9;
        cursor: pointer;
    }

    .option {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .optionSelectedContainer {
        width: 20px;
    }

    .optionSelected {
        background-color: #FFC200;
        border-radius: 10px;
        width: 15px;
        height: 15px;
    }
}