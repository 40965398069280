.collapsedContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    padding: 8px;

    .chevronOpen {
        transform: rotate(180deg);
    }

    &:hover {
        background-color: #F5F5F5;
    }
}

.expandedContainer {
    padding: 0 16px;
}