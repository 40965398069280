.backToContainer {
    display: block;
    width: 100%;
    padding-bottom: 0px;
    padding-top: 0px;
    background-color: white;
    border-bottom: #FFC200 solid 2px;
    box-shadow: 0px 4px 5px hsla(0, 97%, 14%, 0.219);
    
    button {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    @media only screen and (min-width: 768px) {
        display: none;
        padding-bottom: 4px;
        padding-top: 4px;

        button {
            padding-bottom: 8px;
            padding-top: 8px;
        }
    }

    .backToContents {
        display: flex;
        align-items: center;
        gap: 8px;

        .backChevron {
            transform: rotate(90deg);
        }
    }
}