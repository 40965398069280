.eventDisplayContainer {
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffda66;
    
    .eventPreviewContainer {
        height: 100%;
        border-radius: 4px;
        background-color: #FFF;

        .eventPreview {
            padding: 16px 16px 0;
            overflow-y: auto;
            position: relative;

            // TODO: Make more stylish by getting header (back to events) & footer heights and join event button
            height: calc(100% - 115px);
            
            @media only screen and (min-width: 768px) {
                height: calc(100% - 75px);
            }
            
            p {
                margin: 0;
            }

            .actionsContainer {
                position: absolute;
                top: 10px;
                right: 10px;
                gap: 8px;
                display: flex;

                .actionButton {
                    border-radius: 50%;
                    cursor: pointer;
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    -webkit-filter: brightness(100%);

                    &:hover {
                        -webkit-filter: brightness(70%);
                    }

                    > svg {
                        width: 28px;
                    }

                    &.edit {
                        background-color: #556B2F;
                        
                        > svg {
                            fill: #FFC200;
                        }
                    }
                    
                    &.delete {
                        background-color: #FF6347;
                        
                        > svg {
                            fill: #FFF;
                        }
                    }
                }
            }

            .infoContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 20px;

                .title {
                    font-size: 32px;
                    line-height: 44px;
                }
            }

            .sizedInfo {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .infoItem {
                margin-bottom: 16px;
            }

            .infoItemText {
                text-align: center;
                font-size: 20px;
                line-height: 26px;
            }

            .eventName {
                text-align: center;
                font-size: 32px;
                line-height: 44px;
            }

            .eventLocation {
                text-align: center;
                font-size: 24px;
                line-height: 34px;
            }

            .attendeeList {
                display: flex;
                align-items: center;
            }

            .attendeeImagesContainer {
                display: flex;
                flex-direction: row;
                width: 140px;
                justify-content: space-around;
                margin-bottom: 4px;
            }

            .attendeeImage {
                height: 40px;
                width: 40px;
                border-radius: 25px;
                background-color: #FFC200;
            }

            .attendeeInfoRow {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
                font-size: 24px;
                line-height: 34px;
            }

            .attendeeInfoRowImage {
                height: 60px;
                width: 60px;
                border-radius: 30px;
            }

            .attendeeSeparator {
                margin: 16px 0;
            }

            .locationText {
                text-align: center;
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}

.navigation {
    cursor: pointer;

    .prev {
        position: absolute;
        top: 50%;
        left: 5%;

        .chevron {
            transform: rotate(90deg);
        }
    }

    .next {
        position: absolute;
        top: 50%;
        right: 5%;

        .chevron {
            transform: rotate(270deg);
        }
    }
}

.actions {
    display: flex;
    gap: 20px;

    > div {
        display: flex;
        flex-direction: column;
        bottom: 3%;
        cursor: pointer;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        width: 50px;
        height: 50px;

        > svg {
            width: 40px;
            height: 40px;
            fill: #FFF;
        }
    }
    
    .approve {
        position: absolute;
        left: 45%;

        .icon {
            background-color: #556B2F;
        }
    }
    
    .decline {
        position: absolute;
        left: 52%;

        .icon {
            background-color: #FF6347;
        }
    }
}