.groupsPageContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    @media only screen and (min-width: 768px) {
        justify-content: flex-start;
    }

    .tablesContainer {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;

        &.showingModal, &.hasSelectedGroup {
            display: none;
        }

        @media only screen and (min-width: 768px) {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &.hasSelectedGroup {
                display: flex;
            }
        }
    }

    .infoContainer {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        @media only screen and (min-width: 768px) {
            display: none;
        }

        > div {
            text-align: center;
        }
    }
}