.legalContainer {
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    max-height: calc(100dvh - 134px); // subtract header, footer, and filter bar
    
    .legalHeader {
        color: #FFC200;
        font-family: "Pacifico";
        font-size: 28px;
        line-height: 42px;
        margin: 0;
    }


    .modalLinks {
        &.modalOpen {
            display: none;
            
            @media only screen and (min-width: 768px) {
                display: block;
            }
        }
        
        .legalLink {
            padding: 8px 0;

            > span {
                cursor: pointer;
                color: #0000EE;
            }
        }
    }

    .modalMobileContents {
        display: block;

        @media only screen and (min-width: 768px) {
            display: none;
        }
    }
}

.modalContainer {
    .modalHeader {
        text-align: center;
        color: #FFC200;
        font-family: "Pacifico";
        font-size: 28px;
        line-height: 42px;
        margin: 0;
    }
    
    .effectiveDate {
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
    }

    .sectionContainer {
        padding: 12px 0;

        .sectionHeader {
            font-size: 20px;
            line-height: 26px;
            margin: 0;
        }

        .sectionInfo {
            padding: 0 16px;
        }
    }
}