.interestChipContainer {
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .interestChip {
        padding: 4px 8px;
        border-radius: 8px;
        background-color: #FFC200;
        align-self: flex-start;
        color: #002147;
    }

    .showMore {
        color: #556B2F;
    }
}