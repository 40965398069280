.sidebarContainer {
    display: flex;
    flex-direction: column;
    height: 100%;

    .eventsListContainer {
        display: flex;
        flex-direction: column;
        height: 100%;

        &.hiddenMobile {
            display: none;

            @media only screen and (min-width: 768px) {
                display: flex;
            }
        }

        .createButtonContainer {
            box-shadow: 0px -4px 5px  hsla(0, 97%, 14%, 0.219);

            > button {
                width: 100%;
                border-radius: 0;
            }
        }

        .eventsContainer {
            height: 100%;
            overflow-y: auto;
            background-color: #ffda66;
            padding: 8px;

            .eventsContainerDisplayItems {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }
    }

    .filtersContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        padding: 8px 8px 0;

        @media only screen and (min-width: 768px) {
            display: none;
        }
    }
}

.infiniteTextContainer {
    text-align: center;
}

.createEventModal {
    display: none;

    @media only screen and (min-width: 768px) {
        display: none;
    }
}