.card {
    border: 4px solid #FFC200;
    border-radius: 4px;
    padding: 16px;
    caret-color: transparent;
    cursor: pointer;
    color: #002147;

    .title {
        font-family: "Pacifico";
        font-size: 32px;
        line-height: 44px;
    }

    .subtitle {
        padding: 8px 0;
        font-family: "Quicksand";
        font-size: 20px;
        line-height: 26px;
    }
}
