.headerContainer {
    position: sticky;
    top: 0;
    z-index: 100;

    .header {
        padding: 0 16px;
        caret-color: transparent;
        background-color: white;
        border-bottom: #FFC200 1px solid;
        
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .logo {
            display: flex;
            align-items: center;
            cursor: pointer;
            grid-column: 1 / 2;
            grid-row: 1;

            .logoImage {
                height: 32px;
                width: 32px;

                @media only screen and (min-width: 768px) {
                    height: 64px;
                    width: 64px;
                }
            }
        }

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #FFC200;
            font-family: "Pacifico";
            font-size: 24px;
            grid-column: 2 / 3;
            grid-row: 1;
            
            @media only screen and (min-width: 768px) {
                font-size: 32px;
                line-height: 34px;
            }
        }

        
        .menuContainer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            grid-column: 3 / 3;
            grid-row: 1;
            
            .loginButtonContainer > button {
                padding-top: 2px;
                padding-bottom: 2px;
    
                @media only screen and (min-width: 768px) {
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            }

            .navMenuButtonContainer {
                button {
                    &:first {
                    padding: 0;
                    height: 32px;
                    width: 32px;
                    }
                }
            }
        }
    }

    .mobileNav {
        position: absolute;
        height: 50%;
        width: 100%;
        background-color: #FFF;
        border-bottom: 1px solid #556B2F;
        display: block;

        @media only screen and (min-width: 768px) {
            display: none;
        }
    }
}