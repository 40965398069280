.groupViewContainer {
    display: block;
    height: 100%;
    width: 100%;
    overflow-y: hidden;

    @media only screen and (min-width: 768px) {
        display: none;
    }

    .groupViewInfo {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow-y: auto;

        // TODO: Make more stylish by getting header (back to events) & footer heights and join event button
        height: calc(100% - 140px);

        .title {
            font-family: "Pacifico";
            font-size: 24px;
            line-height: 30px;
            color: #FFC200;
        }

        .header {
            font-family: "Pacifico";
            font-size: 20px;
            line-height: 26px;
            padding-bottom: 4px;
        }

        .content {
            padding-left: 8px;
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }
    
    .buttonContainer {
        display: flex;
        gap: 8px;
        padding: 16px;

        > button {
            width: 100%;
        }
    }
}