.deleteEventMobile {
    display: flex;
    flex-direction: column;
    height: 100%;

    .deleteForm {
        padding: 0 16px 16px 16px;
        height: 100%;
        overflow-y: auto;
    }

    @media only screen and (min-width: 768px) {
        display: none;
    }
}